<template>
  <div class="Team part">
    <div class="constrain">
      <line-box class="col-12 mb-12">
        <span v-html="payload.text" />
      </line-box>
      <div class="slider-box">
        <slider
          ref="sliderRef"
          v-slot="{ item: person }"
          class="slider"
          :slides-per-view="{ desktop: 5 }"
          :slides-per-group="{ desktop: 1 }"
          :items="payload.team"
          @progress="onProgress"
        >
          <Go :to="`${payload.ourTeamLink}#person-${person.id}`" class="person block">
            <Image :src="person.bild" />
            <line-box class="box" color="white">
              <span>{{ person.name }}</span>
            </line-box>
          </Go>
        </slider>
        <arrow-left v-if="hasPrev" class="arrow left" @click="prev" />
        <arrow-right v-if="hasNext" class="arrow right" @click="next" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import SwiperCore, { Navigation } from 'swiper';
import ArrowLeft from '@/assets/images/Pfeil_Gallery_left.svg';
import ArrowRight from '@/assets/images/Pfeil_Gallery_right.svg';

SwiperCore.use([Navigation]);

export default {
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const sliderRef = ref(null);
    const progress = ref(0);

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const onProgress = (_, nextProgress) => { progress.value = nextProgress; };

    const hasNext = computed(() => progress.value < 1);
    const hasPrev = computed(() => progress.value > 0);

    return {
      prev,
      next,
      hasNext,
      hasPrev,
      onProgress,
      sliderRef,
      boxed: computed(() => props.payload.optionen?.includes('block')),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(a) {
  @extend .li2;
}

.person {
  @apply rounded-full;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  color: white;
  margin: 0 auto;

  @include responsive('width', 80%, 100%);

  &:hover {
    color: white;
  }

  :deep(img) {
    @include full-overlay();
    object-fit: cover;
    transition: filter var(--speed-fast) ease;
    filter: grayscale(100%);

    &:hover {
      filter: none;
    }
  }
}

.box {
  @extend .n2c;
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  white-space: nowrap;
  pointer-events: none;
}

.link {
  @extend .li1;
}

.slider-box {
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include responsive('right', 90%, 100%);
    @include responsive('height', 15%, 20%);

    &.right {
      right: unset;
      @include responsive('left', 90%, 100%);
    }
  }

  .slider {
    margin: 0 px(20);
  }
}
</style>
